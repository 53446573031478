import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFrameThrottle, usePersistentCallback, useStateRef } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import { useRef, useMemo, useEffect, createContext, useContext, useState, Children, isValidElement } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { GlobalSpinner } from '../Spinner';
import { theme } from '../theme';
import { VirtualizedTableWrapper } from './styled';
import { BATCH_RENDER_COUNT, TABLE_BODY } from './tokens';
const ROW_OFFSET = 5;
const PAGE_RENDER_OFFSET = 0.5; // half of the visible area (extra offset on top and bottom)
const StyledTable = styled.table `
  transform: translate3d(0, 0, 0);
`;
const StyledGlobalSpinner = styled(GlobalSpinner) `
  background: ${theme.colors.white};
`;
const TableContext = createContext({
    initialized: false,
    observe: noop,
    scrollToIndex: noop,
    renderTable: noop,
    unobserve: noop,
    updateRowHeight: noop
});
const TableRerenderContext = createContext({ top: 0, height: 0, startIndex: 0, endIndex: 0 });
export function extractChildRows(record, expandedKeys, rowKey) {
    if (!(record === null || record === void 0 ? void 0 : record.children) || !expandedKeys.has(record[rowKey]))
        return [];
    let childRowKeys = [];
    record.children.forEach((childRecord) => {
        const childKey = childRecord[rowKey];
        childRowKeys.push(childKey);
        // consider only expanded row's children
        if (childRecord.children) {
            childRowKeys = childRowKeys.concat(extractChildRows(childRecord, expandedKeys, rowKey));
        }
    });
    return childRowKeys;
}
export function VirtualizedTable(_a) {
    var _b, _c;
    var { configRef } = _a, tableProps = __rest(_a, ["configRef"]);
    const [observer, setObserver] = useState();
    const [observerStarted, setObserverStarted] = useStateRef(false);
    const tableWrapperRef = useRef(null);
    const tableRef = useRef(null);
    const currentScrollTop = useRef(0);
    const config = configRef.current;
    const { scrollHeight, rowHeight, onScroll } = config;
    const rowHeightMap = useMemo(() => new Map(), []);
    const [vtBoundaries, setBoundaries] = useImmer({
        top: 0,
        startIndex: 0,
        height: scrollHeight,
        endIndex: Math.ceil(scrollHeight / rowHeight) + ROW_OFFSET
    });
    const children = Children.toArray(tableProps.children);
    const { data, expandedKeys } = children.find((child) => isValidElement(child) && child.props.data).props;
    const hasData = !!data.length;
    const getRowHeight = (rowKey) => {
        return rowHeightMap.get(rowKey === null || rowKey === void 0 ? void 0 : rowKey.toString()) || rowHeight;
    };
    const getDataRowKeys = () => {
        const rowKeys = [];
        data.forEach((item) => {
            rowKeys.push(item[config.rowKey]);
            const childRowKeys = extractChildRows(item, expandedKeys, config.rowKey);
            rowKeys.push(...childRowKeys);
        });
        return rowKeys;
    };
    const getTableOffset = (scrollPosition) => {
        let startIndex, endIndex;
        // flatten all expanded rows as rc-table does, so our index logic is correct
        const rowKeys = getDataRowKeys();
        // Working as pages, PAGE_RENDER_OFFSET page above the visible rows and PAGE_RENDER_OFFSET page below
        const top = Math.max(0, scrollPosition - scrollHeight * PAGE_RENDER_OFFSET);
        const bottom = scrollPosition + 2 * scrollHeight * PAGE_RENDER_OFFSET;
        // given current scrollTop find what all rows should be render, find the rendering boundary
        let itemPos = 0, topOffset;
        for (let i = 0, rowsLn = rowKeys.length; i < rowsLn; i++) {
            const key = rowKeys[i];
            const rowHeight = getRowHeight(key);
            if (startIndex === undefined) {
                if (itemPos > top || itemPos + rowHeight > top) {
                    topOffset = itemPos;
                    startIndex = i;
                }
            }
            else if (itemPos < bottom) {
                endIndex = i;
            }
            itemPos += rowHeight;
        }
        const height = itemPos;
        return { height, topOffset, startIndex, endIndex, rowKeys };
    };
    const rerenderTable = usePersistentCallback((scrollTop) => {
        if (!scrollHeight)
            return;
        const { topOffset, startIndex, endIndex, rowKeys, height } = getTableOffset(scrollTop);
        const lastIndex = endIndex === undefined ? rowKeys.length - 1 : endIndex;
        setBoundaries({
            top: topOffset,
            startIndex: startIndex === undefined ? 0 : startIndex,
            endIndex: lastIndex,
            height
        });
        currentScrollTop.current = scrollTop;
    });
    const scrollToIndex = usePersistentCallback((index) => {
        var _a;
        if (!scrollHeight)
            return;
        const rowKeys = getDataRowKeys();
        let scrollTop = 0;
        for (let i = 0; i <= index; i++) {
            scrollTop += getRowHeight(rowKeys[i]);
        }
        const body = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TABLE_BODY}`);
        if (body) {
            body.scrollTo(0, scrollTop > scrollHeight ? scrollTop - scrollHeight : scrollTop - getRowHeight(rowKeys[index]));
        }
    });
    const observerStartedValue = observerStarted.current;
    useEffect(() => {
        if (observerStartedValue) {
            // render the table first time
            rerenderTable(0);
        }
    }, [observerStartedValue, rerenderTable]);
    useEffect(() => {
        var _a;
        if (!hasData)
            return;
        /**
         * Instead of trying to extract height of a element using .offsetHeight (which causes a reflow)
         * We use an intersection observer boundingClientRect.
         * We want the height of an element after they are rendered,and before they are unmounted
         * (as some height change can happen when user is interacting on it while its rendered)
         * Intersection observer serves the purpose.
         */
        const _observer = new IntersectionObserver((entries) => {
            if (!observerStarted.current) {
                setObserverStarted(true);
            }
            entries.forEach((entry) => {
                // store the height of the row
                const recordKey = entry.target.dataset.rowKey;
                if (recordKey !== undefined) {
                    rowHeightMap.set(recordKey, entry.boundingClientRect.height);
                }
            });
        }, 
        // the root margin could be anything just keeping it high so element height are marked as soon as they are rendered
        { root: (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TABLE_BODY}`), threshold: 0, rootMargin: '1000px' });
        setObserver(_observer);
        return () => _observer === null || _observer === void 0 ? void 0 : _observer.disconnect();
    }, [rowHeightMap, hasData, setObserverStarted, observerStarted]);
    const updateRowHeight = usePersistentCallback((rowElm, height) => {
        const recordKey = rowElm.dataset.rowKey;
        if (recordKey !== undefined) {
            rowHeightMap.set(recordKey, height);
        }
    });
    const throttleRaf = useFrameThrottle((tableBody) => {
        const { scrollTop } = tableBody;
        rerenderTable(scrollTop);
        onScroll === null || onScroll === void 0 ? void 0 : onScroll(scrollTop, tableBody);
    }, true);
    useEffect(() => {
        var _a;
        if (!hasData)
            return;
        // listen on scroll event of table body
        const tableBody = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TABLE_BODY}`);
        const onBodyScroll = () => {
            throttleRaf(tableBody);
        };
        tableBody.addEventListener('scroll', onBodyScroll);
        return () => tableBody.removeEventListener('scroll', onBodyScroll);
    }, [rerenderTable, throttleRaf, hasData]);
    useEffect(() => {
        // on scroll height update or change in data (delete or add), or expand/unexpand rerender table
        rerenderTable(currentScrollTop.current);
    }, [rerenderTable, scrollHeight, data.length, [...expandedKeys].join()]); // eslint-disable-line react-hooks/exhaustive-deps
    const tableContext = useMemo(() => {
        var _a;
        return {
            renderTable: rerenderTable,
            updateRowHeight,
            scrollToIndex,
            initialized: !!observer,
            configRef,
            scrollBody: (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TABLE_BODY}`),
            observe: (elm) => {
                observer === null || observer === void 0 ? void 0 : observer.observe(elm);
            },
            unobserve: (elm) => {
                observer === null || observer === void 0 ? void 0 : observer.unobserve(elm);
            }
        };
    }, [observer, scrollToIndex, updateRowHeight, rerenderTable, configRef]);
    // change table scroll from visible to scroll based on content height
    useEffect(() => {
        if (!tableWrapperRef.current)
            return;
        const resizeObserver = new ResizeObserver((entries) => {
            var _a;
            if (!tableWrapperRef.current)
                return;
            const tableBody = (_a = tableWrapperRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TABLE_BODY}`);
            if (tableBody.clientHeight < entries[0].contentRect.height) {
                tableBody.style.overflowY = 'scroll';
            }
            else {
                tableBody.style.overflowY = 'visible';
            }
        });
        resizeObserver.observe(tableWrapperRef.current);
    }, []);
    const TableElm = ((_c = (_b = configRef.current) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.table) || StyledTable;
    return (_jsx(TableContext.Provider, { value: tableContext, children: _jsx(TableRerenderContext.Provider, { value: vtBoundaries, children: _jsx(VirtualizedTableWrapper, { ref: tableWrapperRef, style: { '--min-height': `${vtBoundaries.height}px` }, children: _jsx(TableElm, Object.assign({ ref: tableRef }, tableProps, { style: { position: 'relative', top: `${vtBoundaries.top}px` }, children: children })) }) }) }));
}
export function VirtualizedTableBody(_a) {
    var _b, _c, _d, _e;
    var { children } = _a, bodyProps = __rest(_a, ["children"]);
    const _children = children;
    // rc table renders an additional row on the top to measure row widths, we need to persist the row.
    const firstExtraRowInTable = _children[0];
    const allRows = _children[1];
    const { configRef, renderTable, scrollBody, scrollToIndex } = useContext(TableContext);
    const config = configRef === null || configRef === void 0 ? void 0 : configRef.current;
    const defaultMeasureRowHeights = (_b = config === null || config === void 0 ? void 0 : config.measureRowHeights) !== null && _b !== void 0 ? _b : true;
    const hasVirtualizationControl = Boolean(config === null || config === void 0 ? void 0 : config.getVirtualizationControls);
    const { startIndex, endIndex } = useContext(TableRerenderContext);
    const isArray = Array.isArray(allRows);
    const currentRows = isArray ? allRows.slice(startIndex, endIndex + 1) : allRows;
    const [rowsToMeasure, setRowsToMeasure] = useState(currentRows);
    const TbodyElm = ((_e = (_d = (_c = configRef === null || configRef === void 0 ? void 0 : configRef.current) === null || _c === void 0 ? void 0 : _c.components) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.wrapper) || 'tbody';
    const [measureRowsHeight, toggleMeasuring] = useState(hasVirtualizationControl && defaultMeasureRowHeights);
    const memoizedGetVirtualizationControls = usePersistentCallback((controls) => {
        var _a;
        (_a = config === null || config === void 0 ? void 0 : config.getVirtualizationControls) === null || _a === void 0 ? void 0 : _a.call(config, controls);
    });
    useEffect(() => {
        if (!measureRowsHeight && scrollBody) {
            memoizedGetVirtualizationControls({ scrollToIndex, scrollBody });
        }
    }, [memoizedGetVirtualizationControls, scrollToIndex, scrollBody, measureRowsHeight]);
    // reset virtualization control on unmount
    useEffect(() => {
        return () => {
            memoizedGetVirtualizationControls(undefined);
        };
    }, [memoizedGetVirtualizationControls]);
    // If we have to measure row height of all rows before hand, render in batches so height can be calculated without making the browser go crazy.
    useEffect(() => {
        if (!measureRowsHeight) {
            return;
        }
        const rowsCount = allRows.length - 1;
        let batchIndex = 0;
        const renderRowsAndDelete = () => {
            if (isArray && batchIndex >= rowsCount) {
                clearInterval(renderRowsInBatchedInterval);
                renderTable(0);
                toggleMeasuring(false);
            }
            if (isArray && batchIndex < rowsCount) {
                const rowsBatch = allRows.slice(batchIndex, batchIndex + BATCH_RENDER_COUNT);
                batchIndex += rowsBatch.length;
                setRowsToMeasure(rowsBatch);
            }
        };
        const renderRowsInBatchedInterval = setInterval(renderRowsAndDelete, 50);
        // avoiding running effect due to change in allRows
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const rowsToRender = measureRowsHeight ? rowsToMeasure : currentRows;
    return (_jsxs(TbodyElm, Object.assign({}, bodyProps, { children: [firstExtraRowInTable, rowsToRender, measureRowsHeight ? _jsx(StyledGlobalSpinner, { spinning: true, tip: 'Loading...' }) : null] })));
}
export function VirtualizedRow(props) {
    var _a, _b, _c, _d;
    const trRef = useRef(null);
    const tableContext = useContext(TableContext);
    const trElm = trRef.current;
    useEffect(() => {
        if (!tableContext.initialized || !trElm)
            return;
        tableContext.observe(trElm);
        return () => {
            tableContext.unobserve(trElm);
        };
    }, [tableContext, trElm]);
    const { updateRowHeight } = tableContext;
    useEffect(() => {
        if (!trElm)
            return;
        // if row height updates change the cached height.
        const observer = new ResizeObserver((entries) => {
            var _a;
            const height = (_a = entries[0]) === null || _a === void 0 ? void 0 : _a.contentRect.height;
            if (height !== undefined) {
                updateRowHeight(trElm, height);
            }
        });
        observer.observe(trElm);
        return () => {
            observer.disconnect();
        };
    }, [updateRowHeight, trElm]);
    const TRElm = ((_d = (_c = (_b = (_a = tableContext.configRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.body) === null || _d === void 0 ? void 0 : _d.row) || 'tr';
    return _jsx(TRElm, Object.assign({ ref: trRef }, props));
}
export function useVirtualization(config) {
    // passing virtualization config as ref so that we don't end up creating new Table Component every time config changes
    // Note the inline table function, can create different reference breaking reconciliation
    const configRef = useRef(config);
    configRef.current = config;
    const tableComponents = useMemo(() => {
        var _a, _b, _c;
        return ({
            table: (tableProps) => _jsx(VirtualizedTable, Object.assign({}, tableProps, { configRef: configRef })),
            header: (_a = configRef.current.components) === null || _a === void 0 ? void 0 : _a.header,
            body: {
                wrapper: VirtualizedTableBody,
                row: VirtualizedRow,
                cell: (_c = (_b = configRef.current.components) === null || _b === void 0 ? void 0 : _b.body) === null || _c === void 0 ? void 0 : _c.cell
            }
        });
    }, []);
    return tableComponents;
}
