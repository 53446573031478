import { event as fullStoryEvent, identify, init as FullStoryInit, setUserVars } from '@fullstory/browser';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { addBreadcrumb, setUser } from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { useImmer } from 'use-immer';

import { SentryBreadcrumb } from '../../common/sentry/SentryBreadcrumb';
import { Entity } from '../../common/types/Entity';
import { EntityEvents } from '../../common/user-analytics';
import { UserTypes } from '../../common/UserTypes';
import { csrf } from '../../utils/csrf';
import { useAppMetadata } from '../appMetadata';
import { useGlobalVariables } from '../globalConfig/securedConfigHook';
import { MixPanelContext, MixPanelContextKeys, MixpanelStateType } from './types';
import {
  FullStoryAllowedDomains,
  fullStoryNameSpace,
  fullStoryOrgID,
  getCurrentTimeStamp,
  getMixpanelApIhost,
  getNameFromEmail,
  getSessionValue,
  isFSEnabledForProphecyEmail,
  MIXPANEL___TOKEN
} from './util';

export const useMixPanelContext = () => {
  const user = useAppMetadata()?.user;
  const isMixPanelEnabled = useGlobalVariables().isMixPanelEnabled;
  const [state, setState] = useImmer<MixpanelStateType>({
    enabled: false,
    isUserIdentified: false,
    isMixPanelLoaded: false,
    contexts: {}
  });

  const initMixpanel = usePersistentCallback((email?: string) => {
    setState((draft) => {
      draft.isMixPanelLoaded = true;
      draft.enabled = true;
    });
    mixpanel.init(MIXPANEL___TOKEN, { api_host: getMixpanelApIhost(), ignore_dnt: true });
    mixpanel.set_config({ xhr_headers: { [csrf.key]: csrf.get() } });
    mixpanel.register({ session_id: getSessionValue(), user_type: email || 'Public User' });
  });

  const loadFullStory = usePersistentCallback(async (email?: string) => {
    const isProphecyUser = user?.email || email?.endsWith('@prophecy.io');
    let enabled = false;
    if (FullStoryAllowedDomains.includes(window.location.host)) {
      if (isProphecyUser) {
        enabled = isFSEnabledForProphecyEmail;
      } else {
        enabled = true;
      }
    }
    FullStoryInit({
      orgId: fullStoryOrgID,
      namespace: fullStoryNameSpace,
      devMode: !enabled
    });
  });

  const addContext = usePersistentCallback(<T extends keyof MixPanelContext>(key: T, value: MixPanelContext[T]) => {
    setState((draft) => {
      draft.contexts[key] = value;
    });
  });

  const removeContext = usePersistentCallback((key: MixPanelContextKeys) => {
    setState((draft) => {
      delete draft.contexts[key];
    });
  });

  const privateMixpanelInit = usePersistentCallback(async () => {
    if (!state.isUserIdentified && !state.isMixPanelLoaded) {
      try {
        await setDistinctID();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const publicInitMixpanel = usePersistentCallback(async () => {
    try {
      if (!state.isMixPanelLoaded /* && window.location.hostname === allowedDomain[0]*/) {
        console.warn('Loading Public MixPanel');
        await initMixpanel();
        await track(EntityEvents[Entity.User].home.nonLoggedinUserFirstEvent);
      }
    } catch (error) {
      console.error(error);
    }
  });

  const track = usePersistentCallback(
    async (eventName: string, props: { [key: string]: unknown } = {}, event_category?: string) => {
      if (state.enabled) {
        if (event_category) {
          props['event_category'] = event_category;
        }
        const tab_session_id = getSessionValue();
        mixpanel.track(eventName, {
          event_data: { ...props, tab_session_id },
          contexts: { ...state.contexts }
        });
        //Track event in full story
        fullStoryEvent(eventName, {
          event_data: { ...props, tab_session_id },
          contexts: { ...state.contexts }
        });
        addBreadcrumb({
          category: SentryBreadcrumb.Mixpanel,
          message: eventName,
          level: 'info'
        });
      }
    }
  );

  const setDistinctID = usePersistentCallback(async (currentEmail?: string) => {
    const email = (user?.email || currentEmail)?.toLowerCase();
    if (isMixPanelEnabled) {
      await initMixpanel(currentEmail);
      await track(EntityEvents[Entity.User].home.loggedInUserFirstEvent, {
        userId: user?.id,
        email,
        source: user?.type
      });
    }
    const tab_session_id = getSessionValue();
    if (email) {
      //Need to set the unique email only, so if user is loggedin with same email then all the events will be merged together
      mixpanel.identify(email);
      mixpanel.people.set({
        $email: email,
        $userId: user?.id,
        $last_seen: new Date().toISOString(),
        $tab_session_id: tab_session_id,
        $time: getCurrentTimeStamp(),
        $insert_id: nanoid(10)
      });

      //TODO remove after ff poc
      identify(email, {
        displayName: email,
        email,
        tab_session_id,
        useID: user?.id
      });
      //User identified successfully against a email id
      setState((draft) => {
        draft.isUserIdentified = true;
      });
    }
  });

  const setOnceEvent = usePersistentCallback(async (props: { [key: string]: string | undefined }) => {
    if (state.enabled) {
      //Check if user is not identified just before setting event
      if (!state.isUserIdentified) {
        await setDistinctID(props?.email || user?.email);
      }
      mixpanel.people.set_once(props);
    }
  });

  const identifyAndSetPeople = usePersistentCallback(
    async (
      email: string,
      obj: {
        firstName: string;
        lastName?: string;
        source?: UserTypes;
        invitation?: string;
        userId?: string;
        company?: string;
        signup_date?: string;
      }
    ) => {
      if (state.enabled) {
        obj = obj || {};
        email = email?.toLowerCase();
        //Identify user if not identified or override the user detail again if something name or anything else changed
        await setDistinctID(email);
        const firstName = obj.firstName;
        const lastName = obj.lastName && obj.lastName !== '--' ? obj.lastName : '';
        const name = firstName || lastName ? `${firstName ? firstName : ''} ${lastName}` : getNameFromEmail(email);
        const invitation = obj?.invitation;
        const tab_session_id = getSessionValue();
        const peopleObj: { [key: string]: string | undefined } = {
          $name: name,
          $email: email,
          id: email,
          $company: obj.company,
          $userId: obj.userId,
          $firstName: obj.firstName,
          $lastName: lastName,
          $signup_date: obj.signup_date,
          $source: obj.source || user?.type,
          $last_seen: new Date().toISOString(),
          $tab_session_id: tab_session_id,
          $time: getCurrentTimeStamp(),
          $insert_id: nanoid(10)
        };

        if (invitation) {
          peopleObj.$invitation = invitation;
        }
        setUserVars(peopleObj);
        mixpanel.people.set(peopleObj);
        setUser({ email, username: name, ...obj, lastName, uid: obj.userId });
      }
    }
  );

  return useMemo(
    () => ({
      publicInitMixpanel,
      setOnceEvent,
      track,
      loadFullStory,
      privateMixpanelInit,
      identifyAndSetPeople,
      addContext,
      removeContext
    }),
    [
      privateMixpanelInit,
      publicInitMixpanel,
      loadFullStory,
      track,
      identifyAndSetPeople,
      setOnceEvent,
      addContext,
      removeContext
    ]
  );
};
