import { theme } from '../theme/theme';
export const TABLE_CLASS = 'ui-table';
export const TABLE_EXPAND_ICON_CLASS = 'ui-table-row-expand-icon-collapsed';
export const TABLE_ROW_EXPAND_ICON_CLASS = 'ui-table-row-expand-icon';
export const TABLE_MEASURE_ROW_CLASS = 'ui-table-measure-row';
export const TABLE_ROW_CLASS = 'ui-table-row';
export const TABLE_THEAD_CLASS = 'ui-table-thead';
export const TABLE_CELL_CLASS = 'ui-table-cell';
export const TABLE_CONTAINER = `ui-table-container`;
export const TABLE_BODY = `ui-table-body`;
export const TABLE_TBODY = `ui-table-tbody`;
export const TABLE_CONTENT = `ui-table-content`;
export const TABLE_CELL_FIX_LEFT = `ui-table-cell-fix-left`;
export const TABLE_CELL_FIX_RIGHT = `ui-table-cell-fix-right`;
export const TABLE_CELL_FIX_LEFT_LAST = `ui-table-cell-fix-left-last`;
export const TABLE_CELL_FIX_RIGHT_LAST = `ui-table-cell-fix-right-last`;
export const TABLE_PLACEHOLDER = 'ui-table-placeholder';
export const TABLE_CELL_ROW_HOVER = 'ui-table-cell-row-hover';
export const TABLE_FOOTER = 'ui-table-footer';
export const TABLE_CELL_SCROLLBAR = 'ui-table-cell-scrollbar';
export const TABLE_BOTTOM_OBSERVER_ELEMENT = 'ui-table-observer-element';
export const CONFIG_FIELD_TAG_CONTAINER = 'config-field-tag-container';
export const CONFIG_FIELD_SEARCH_INPUT = 'config-field-search-input';
export const CONFIG_FIELD_CARET_CONTAINER = 'config-field-caret-container';
export const CONFIG_FIELD_TAG_ICON = 'config-field-tag-icon';
export const IGNORE_TABLE_STYLES = 'ignore-table-styles';
export const BATCH_RENDER_COUNT = 50;
export const SCROLL_POSITION_THRESHOLD = 50;
export const tokens = {
    Table: {
        borderColor: theme.colors.grey300,
        borderRadius: theme.radius.m,
        fontFamily: theme.fontFamily.text,
        fontSize: theme.fontSizes.x13,
        fontWeight: theme.fontWeight.normal,
        columnResizer: {
            right: `-${theme.sizes.x10}`,
            zIndex: 5,
            color: theme.colors.primary300,
            fontSize: theme.fontSizes.x16,
            width: theme.sizes.x20,
            height: '100%',
            padding: '2px'
        },
        sort: {
            right: `-${theme.sizes.x20}`
        }
    },
    Row: {
        backgroundColor: theme.colors.white,
        height: '35px',
        DummyRow: {
            backgroundColor: theme.colors.grey50
        },
        disabled: {
            backgroundColor: theme.colors.grey50
        },
        hover: {
            backgroundColor: theme.colors.grey50
        },
        current: {
            borderWith: '3px',
            borderColor: theme.colors.grey300
        }
    },
    Cell: {
        padding: theme.spaces.x12
    },
    Head: {
        backgroundColor: theme.colors.grey50,
        fontWeight: theme.fontWeight.normal
    },
    Icon: {
        color: theme.colors.grey700,
        fontSize: theme.sizes.x20,
        hover: {
            color: theme.colors.grey900
        }
    },
    ExcelTable: {
        Input: {
            fontSize: theme.fontSizes.x13,
            focus: {
                borderColor: theme.colors.primary500
            },
            error: {
                borderColor: theme.colors.error500
            }
        },
        Cell: {
            padding: theme.spaces.x8
        }
    },
    SelectionActions: {
        zIndex: theme.zLayer.xxl,
        height: theme.sizes.x45,
        padding: theme.spaces.x12,
        color: theme.colors.grey500,
        gap: theme.spaces.x8
    },
    RowAction: {
        zIndex: theme.zLayer.m
    },
    GhostDragElement: {
        padding: theme.spaces.x12,
        backgroundColor: theme.colors.primary50,
        borderRadius: theme.radius.m,
        color: theme.colors.primary700
    },
    Keyword: {
        borderRadius: theme.radius.s,
        fontSize: theme.sizes.x12,
        width: '50px'
    },
    Addon: {
        background: 'white',
        color: theme.colors.grey400
    },
    LogicalRowCell: {
        backgroundColor: theme.colors.warning25
    }
};
export const ROW_MINIMUM_HEIGHT = parseInt(tokens.Row.height, 10);
